import React from 'react';

/**
 * React component that highlights matched portions of a string based on the provided indices by wrapping them in <b></b> tags.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.match - The match object containing indices and the value.
 * @param {Array<Array<number>>} props.match.indices - An array of start and end index pairs representing the matched parts.
 * @param {string} props.match.value - The string where matches are found and should be highlighted.
 * @param {string} [props.match.key] - Optional key indicating the field being matched (not used in rendering).
 * @returns {JSX.Element} A React element with matched portions of the string wrapped in <b></b> tags.
 *
 * @example
 * const match = {
 *   indices: [
 *     [0, 2],
 *     [3, 5]
 *   ],
 *   value: "AAL1863",
 *   key: "fli"
 * };
 * <HighlightMatch match={match} />
 */
const HighlightMatch = ({ match }) => {
  const { indices, value } = match;
  let parts = [];
  let currentIndex = 0;

  indices.forEach(([start, end], index) => {
    if (currentIndex < start) {
      parts.push(<span key={`part-${index}-before`}>{value.slice(currentIndex, start)}</span>);
    }

    parts.push(<b key={`part-${index}-match`}>{value.slice(start, end + 1)}</b>);

    currentIndex = end + 1;
  });

  if (currentIndex < value.length) {
    parts.push(<span key="part-after">{value.slice(currentIndex)}</span>);
  }

  return <span>{parts}</span>;
};

export default HighlightMatch;
