import React, { useEffect, useState } from 'react';
import './FlightHistory.css'
import 'rsuite/dist/rsuite.min.css';
import MenuButton from '../SideNavigation/components/MenuButton';
import { FlightHistoryData } from './FlightHistoryData';
import { FlightHistoryFilter } from './FlightHistoryFilter';
import { FlightHistoryAddress } from './FlightHistoryAddress';
import { FlightHistoryExport } from './FlightHistoryExport';
import api from 'api/api';
import { useHistory } from 'react-router-dom';

const targetProgress = 80; // Максимальное значение прогресса

const FlightHistorySettings = ({ map, centerPoint, setLocationFromSearch, onHistoryResponse, onCenterTypeChange }) => {
  const history = useHistory();

  
  const [data, setData] = useState({
    centerType: 'airport',
    center: [0,0],
    altitude: 1000
  })
  const [progress, setProgress] = useState(0); // Храним текущий прогресс
  const [isLoading, setLoading] = useState(false)
  const [isHistoryOpened, setHistoryOpenend] = useState(true)

  const onHistoryToggle = () => setHistoryOpenend((state) => !state)

  const handleDataChange = (payload) => setData({ ...data, ...payload })

  const loadHistoryData = async () => {
    setLoading(true)
    const historyData = await api.getHistoryData({
      ...data,
      center: [...data.center].reverse()
    })

    onHistoryResponse(historyData)
    setProgress(100)
    setLoading(false)

    setTimeout(() => {
      setProgress(0)
    }, 2000);
  }

  const closeModal = () => history.push('/dashboard')

  useEffect(() => {
    if (!isLoading) return;
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < targetProgress) {
          const increment = Math.random() * (5 - 0.1) + 0.1;
          return Math.min(prev + increment, targetProgress); 
        }
        clearInterval(interval); 
        return prev;
      });
    }, 1000);

    return () => clearInterval(interval); 
  }, [isLoading]);

  useEffect(() => {
    handleDataChange({center: centerPoint})
  }, [centerPoint])

  return ( 
  <div className={isHistoryOpened ? 'flight-history' : 'flight-history flight-history--hidden' }> 
      <div className='flight-history-header' onClick={onHistoryToggle}>
        <div className='flight-history-header__title'>
          Flight history
        </div>
        <MenuButton className='flight-history-header__close' isCloseButton onClick={closeModal} />
      </div>
      <div className='flight-history-loader'>
       <div className='flight-history-loader__bar' style={{
          width: `${progress}%`,
          opacity: progress ? 1 : 0
        }}></div>
      </div>
    <div className='flight-history-body'>
      <FlightHistoryData onChange={handleDataChange} onCenterTypeChange={onCenterTypeChange} />
      <FlightHistoryFilter onChange={handleDataChange} onSubmit={loadHistoryData} />
      <FlightHistoryAddress setLocationFromSearch={setLocationFromSearch} />
      <FlightHistoryExport />
    </div>
  </div> 
  );
}

export { FlightHistorySettings };