import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './FormGroup.css';

const FormGroupRow = ({
  className,
  children,
  filled,
  button,
  checkbox,
  singleCheckbox,
  noLabel,
  flex,
  gap,
  padding = true,
}) => (
  <div
    className={clsx('form-group-row', className, {
      'form-group-row_filled': filled,
      'form-group-row_gap': gap,
      'form-group-row_button': button,
      'form-group-row_padding': padding,
      'form-group-row_checkbox': checkbox,
      'form-group-row_checkbox-single': singleCheckbox,
      'form-group-row_checkbox_no-label': noLabel,
      'form-group-row_flex': flex,
    })}
  >
    {children}
  </div>
);

FormGroupRow.propTypes = {
  children: PropTypes.node,
  filled: PropTypes.bool,
  button: PropTypes.bool,
  padding: PropTypes.bool,
  checkbox: PropTypes.bool,
  singleCheckbox: PropTypes.bool,
  noLabel: PropTypes.bool,
  flex: PropTypes.bool,
  gap: PropTypes.bool,
  className: PropTypes.string,
};

const FormGroup = ({ children, title, filled, className }) => {
  return (
    <div
      className={clsx('form-group', className, {
        'form-group_filled': filled,
      })}
    >
      {title && <div className="form-group__name">{title}</div>}
      <div className="form-group__items">{children}</div>
    </div>
  );
};

FormGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  filled: PropTypes.bool,
};

FormGroup.Row = FormGroupRow;
export default FormGroup;
