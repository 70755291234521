import { MAX_SECONDS_BETWEEN_FLIGHT_POINTS } from 'utils/globalConstants';
import { zoomDiameterToMaxMinValues, isSourceVisible } from 'utils/mapUtils';
import { getWildcardRegExp } from 'utils/common.utils';

const MAX_ALT_FOR_SIMPLIFIED_VIEW = 5000;
// Max distance at which point is considered close to bounds
const BOUNDS_ERROR = 0.02;

const isFlightOutOfBounds = (path, bounds) => {
  return (
    path.filter((point) => {
      const { lat, lon, alt } = point;

      const latInBounds = lat <= bounds.maxLat && lat >= bounds.minLat;
      const lonInBounds = lon <= bounds.maxLon && lon >= bounds.minLon;
      const altInBounds = alt <= MAX_ALT_FOR_SIMPLIFIED_VIEW;

      return latInBounds && lonInBounds && altInBounds;
    }).length > 0
  );
};

const isPointCloseToBounds = (point, bounds) => {
  const { lat, lon } = point;

  const isLatCloseToBounds = lat >= bounds.maxLat - BOUNDS_ERROR || lat <= bounds.minLat + BOUNDS_ERROR;
  const isLonCloseToBounds = lon >= bounds.maxLon - BOUNDS_ERROR || lon <= bounds.minLon + BOUNDS_ERROR;

  return isLatCloseToBounds || isLonCloseToBounds;
};

export const getDatesArray = (dateStart, dateEnd) => {
  const result = [];
  const timestampStart = dateStart.getTime();
  const timestampEnd = dateEnd.getTime();
  const hoursBetweenDates = Math.floor((timestampEnd - timestampStart) / 3600000);

  if (hoursBetweenDates === 0) {
    return [
      {
        dateStart: new Date(timestampStart).toISOString(),
        dateEnd: new Date(timestampEnd).toISOString(),
      },
    ];
  }

  for (let i = 0; i < hoursBetweenDates; i += 1) {
    result.push({
      dateStart: new Date(timestampStart + 3600000 * i).toISOString(),
      dateEnd: new Date(timestampStart + 3600000 * (i + 1) - 1000).toISOString(),
    });
  }

  return [
    ...result,
    {
      dateStart: new Date(timestampStart + 3600000 * hoursBetweenDates).toISOString(),
      dateEnd: new Date(timestampEnd).toISOString(),
    },
  ];
};

/**
 * Recursively divides flight path into segments when they go out of bounds
 * @param {*} fpth list of points
 * @param {*} zoneBounds noise zone bounds
 * @returns
 */
const splitPathSegments = (fpth, zoneBounds) => {
  let processedFlightPaths = [];
  for (let i = 0; i < fpth.length - 1; i += 1) {
    const currentUti = fpth[i].uti;
    const nextUti = fpth[i + 1].uti;
    if (
      isPointCloseToBounds(fpth[i], zoneBounds) &&
      Math.abs(nextUti - currentUti) > MAX_SECONDS_BETWEEN_FLIGHT_POINTS * 2
    ) {
      const part1 = fpth.slice(0, i);
      const part2 = splitPathSegments(fpth.slice(i + 1, fpth.length), zoneBounds);
      processedFlightPaths = [...processedFlightPaths, part1, ...part2];
      break;
    }
  }
  return processedFlightPaths.length > 0 ? processedFlightPaths : [fpth];
};

export const makeSplitData = (flightpaths) => {
  const splitData = [];

  flightpaths.forEach((path) => {
    const splitPointsIndexes = [0];
    path.fpth.forEach((point, i) => {
      const nextPoint = path.fpth[i + 1];
      if (nextPoint && Math.abs(point.uti - nextPoint.uti) > MAX_SECONDS_BETWEEN_FLIGHT_POINTS) {
        splitPointsIndexes.push(i + 1);
      }
    });

    splitPointsIndexes.forEach((splitIndex, i) => {
      const nextIndex = splitPointsIndexes[i + 1];

      const fpth = path.fpth.slice(splitIndex, nextIndex);
      const newPath = {
        ...path,
        utiregInitial: path.utireg,
        utireg: path.utireg + fpth[0]?.uti + splitIndex,
        fpth,
      };

      if (fpth.length) {
        splitData.push(newPath);
      }
    });
  });

  return splitData;
};

export const makeSimplifiedData = (data, airportBounds, zoneBounds) => {
  const result = [];

  // remove flights which have no intersections with 2NM x 2NM zone around airport
  const filteredFlights = data.filter((flight) => {
    return isFlightOutOfBounds(flight.fpth, airportBounds);
  });

  // for each flightpath segment of a whole flight create a copy with that flightpath segment
  filteredFlights.forEach((flight) => {
    splitPathSegments(flight.fpth, zoneBounds)
      .filter((fpth) => fpth.length > 0)
      .forEach((fpth) => {
        result.push({
          ...flight,
          fpth,
          utireg: `${flight.utireg}-${fpth[0].uti}-${fpth.length}`,
        });
      });
  });

  return result
    .filter((item) => item.fpth.length > 0)
    .map((item) => {
      const start = item.fpth[0];
      const end = item.fpth[item.fpth.length - 1];

      return {
        ...item,
        isAsc: start.alt - end.alt,
      };
    });
};

export const filterNoiseData = (noiseData, filtersBag) => {
  const { filters, airport, flightsPerActivityHash, sources, origins } = filtersBag;

  let processingData = [...noiseData];

  // filter by sources
  processingData = processingData.filter((flightpath) => {
    return isSourceVisible(flightpath.src, flightpath.cla, sources);
  });

  // filter by sources
  processingData = processingData
    .map((flightpath) => {
      return {
        ...flightpath,
        fpth: flightpath.fpth.filter((point) => {
          return point.origin ? origins[point.origin] : true;
        }),
      };
    })
    .filter((flightpath) => flightpath.fpth.length);

  Object.entries(filters).forEach(([key, filterValue]) => {
    if (!filterValue) return;
    switch (key) {
      case 'reg':
      case 'fli': {
        const keyRegex = getWildcardRegExp(filterValue);
        processingData = processingData.filter((path) => keyRegex.test(path[key]));
        break;
      }

      case 'alt': {
        processingData = processingData
          .map((path) => {
            return { ...path, fpth: path.fpth.filter((point) => point.alt < filterValue) };
          })
          .filter((path) => path.fpth.length);
        break;
      }
      case 'smp': {
        const airportBounds = zoomDiameterToMaxMinValues(2, airport.lat, airport.lon);
        const zoneBounds = zoomDiameterToMaxMinValues(60, airport.lat, airport.lon);
        processingData = makeSimplifiedData(processingData, airportBounds, zoneBounds);
        break;
      }

      case 'activity': {
        const flightsToFilterBy = flightsPerActivityHash?.[filters.activity];
        if (flightsToFilterBy?.length) {
          processingData = processingData.filter((path) => {
            return flightsToFilterBy.some((flight) => flight === path.fli?.trim());
          });
        }
        break;
      }

      default:
        break;
    }
  });

  return processingData;
};

/**
 * Calculates altitude according to ISA (Internation standard atmosphere)
 * @param {*} pressureAlt pressure altitude (ft)
 * @param {*} qnh QNH or QFE pressure to correct to (hPa)
 */
export const getAltitudePressureCorrected = (pressureAlt, QNH) => {
  const ISA_PRESSURE = 1013.25;

  const pressureCorrection = (QNH - ISA_PRESSURE) * 30;
  return pressureAlt + pressureCorrection;
};

const COEF_INHG_TO_HPA = 33.86389; // inHg to hPa
const COEF_METER_TO_FT = 3.28084;

export const ALTITUDE_TYPE_RAW = 'alt_raw';
export const ALTITUDE_TYPE_GNSS_RAW = 'alt_gnss_raw';
export const ALTITUDE_TYPE_GNSS_CORRECTED = 'alt_gnss_corrected';
export const ALTITUDE_TYPE_QNH_CORRECTED = 'alt_qnh_corrected';
export const ALTITUDE_TYPE_QFE_CORRECTED = 'alt_qfe_corrected';
export const ALTITUDE_TYPE_QNHS_CORRECTED = 'alt_qnhs_corrected';

export const correctAndFilterAltitude = (flightpath, altitudeType) => {
  switch (altitudeType) {
    case ALTITUDE_TYPE_RAW: {
      const fpth = flightpath.fpth?.map((point) => {
        point.altc = point.alt;
        return point;
      });
      return { ...flightpath, fpth };
    }

    case ALTITUDE_TYPE_GNSS_RAW: {
      const fpth = flightpath.fpth
        .filter((point) => point.altg)
        .map((point) => {
          const altc = point.altg;
          return { ...point, altc };
        });

      return { ...flightpath, fpth };
    }

    case ALTITUDE_TYPE_GNSS_CORRECTED: {
      const fpth = flightpath.fpth
        .filter((point) => point.altgc)
        .map((point) => {
          // altgc is coming in meters
          const altc = point.altgc * COEF_METER_TO_FT;
          return { ...point, altc };
        });

      return { ...flightpath, fpth };
    }

    case ALTITUDE_TYPE_QNH_CORRECTED: {
      const fpth = flightpath.fpth
        .filter((point) => point.qnh)
        .map((point) => {
          const altc = getAltitudePressureCorrected(point.alt, point.qnh);
          return { ...point, altc };
        });

      return { ...flightpath, fpth };
    }
    case ALTITUDE_TYPE_QFE_CORRECTED: {
      const fpth = flightpath.fpth
        .filter((point) => point.qfe)
        .map((point) => {
          // QFE is coming in inHg
          const QFE_HPA = point.qfe * COEF_INHG_TO_HPA;
          const altc = getAltitudePressureCorrected(point.alt, QFE_HPA);
          return { ...point, altc };
        });

      return { ...flightpath, fpth };
    }
    case ALTITUDE_TYPE_QNHS_CORRECTED: {
      const fpth = flightpath.fpth
        .filter((point) => point.qnhs)
        .map((point) => {
          const altc = getAltitudePressureCorrected(point.alt, point.qnhs);
          return { ...point, altc };
        });

      return { ...flightpath, fpth };
    }

    default:
      return flightpath;
  }
};

export function makeCorrectedData(noiseData, altitudeType) {
  return noiseData
    .map((flightpath) => correctAndFilterAltitude(flightpath, altitudeType))
    .filter((flightpath) => flightpath.fpth.length);
}
