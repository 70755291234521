import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { getNodePosition } from 'utils/modalHelpers';
import { MAP_CONTROLS } from 'utils/globalConstants';
import { permissions } from 'utils/userPermissions';
import { isCenterOfBounds } from 'utils/mapUtils';
import { setModalPosition, toggleControl, resetControls } from 'redux/modules/modal';
import { setZoomBounds, setViewport } from 'redux/modules/map';
import AircraftLookupModal from './AircraftLookupModal';
import MapControl from './MapControl';

import './MapControls.css';

const MIN_ZOOM = 2;
const MAX_ZOOM = 21;
// TODO: find a way to configure properly visible controls
const MapControls = ({ children, hall, isBottomBarVisible, isDisclaimerVisible, isNoiseAbatementPage = false }) => {
  const dispatch = useDispatch();
  const { controls } = useSelector((state) => state.modal);
  const { userSettings } = useSelector((state) => state.auth);
  const { filters } = useSelector((state) => state.map);
  const { permissionsList } = useSelector((state) => state.auth.userInfo);
  const isFlightsModalAllowed = permissionsList.includes(permissions.PAGE_AIRPORT_TV_SCREENS);
  const isAtcModalAllowed = permissionsList.includes(permissions.OPTION_ATC_FEED);
  const filtersAppliedAmount = Object.values(filters).filter((filter) => {
    const value = filter.value?.[0] || filter.value?.[1];
    return value && value !== 'any';
  }).length;

  const { zoomBounds, viewport } = useSelector((state) => state.map);

  useEffect(() => {
    return () => {
      dispatch(resetControls());
    };
  }, [dispatch]);

  const calculateZoom = (newZoom) => {
    if (newZoom < MIN_ZOOM) return MIN_ZOOM;
    return Math.min(newZoom, MAX_ZOOM);
  };

  const handleZoomChange = (zoomDiff) => {
    const newZoom = viewport.zoom + zoomDiff;
    dispatch(setViewport({ ...viewport, zoom: calculateZoom(newZoom) }));
  };

  const centerMap = useCallback(() => {
    if (!navigator.geolocation) {
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const newCenter = [position.coords.latitude, position.coords.longitude];
        dispatch(setViewport({ ...viewport, center: newCenter }));
      },
      (error) => {
        console.warn('[ERROR] Cannot get geolocation', error);
      }
    );
  }, [dispatch, viewport]);

  const handleViewportChange = (newViewport) => {
    if (!newViewport?.center || !newViewport?.zoom) return;
    dispatch(setViewport({ ...newViewport, zoom: calculateZoom(newViewport.zoom) }));
    if (isCenterOfBounds(newViewport, zoomBounds)) return;
    if (zoomBounds) dispatch(setZoomBounds(null));
  };

  const handleOpenModal = (e, controlName) => {
    const position = getNodePosition(e.currentTarget);
    // TODO: Fix. Hack! Should use some more sophisticated approach for calculating top, instead of hard coded value
    // 485 is the value that is adjusted to height of the page and just based on tests
    const pageHeight = window.innerHeight;
    const hardCodedTop = controlName === MAP_CONTROLS.FILTERS && pageHeight <= 768 ? 450 : position.top;

    dispatch(
      setModalPosition({
        name: controlName,
        position: {
          top: hardCodedTop,
          left: position.left,
        },
        useOffset: true,
      })
    );
    dispatch(toggleControl(controlName));
  };

  const renderAllControls = () => {
    return (
      <>
        <MapControl
          title="Select layers"
          className="map-controls__control"
          type={MAP_CONTROLS.MAP_LAYERS}
          active={controls[MAP_CONTROLS.MAP_LAYERS]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.MAP_LAYERS);
          }}
        />
        {isFlightsModalAllowed && (
          <MapControl
            title="Open flights info"
            className="map-controls__control"
            type={MAP_CONTROLS.FLIGHTS}
            active={controls[MAP_CONTROLS.FLIGHTS]}
            onClick={(e) => {
              handleOpenModal(e, MAP_CONTROLS.FLIGHTS);
            }}
          />
        )}
        <MapControl
          title="Select ATC"
          className="map-controls__control"
          type={MAP_CONTROLS.LIVE_ATC}
          active={controls[MAP_CONTROLS.LIVE_ATC]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.LIVE_ATC);
          }}
          disabled={!isAtcModalAllowed}
        />
        <div className="map-controls__control-br" />
        <MapControl
          title="Aircraft Lookup"
          className={clsx('map-controls__control')}
          innerClassName={clsx('map-controls__aircraft-lookup-form', {
            'map-controls__aircraft-lookup-form_open': controls[MAP_CONTROLS.AIRCRAFT_LOOKUP],
          })}
          type={MAP_CONTROLS.AIRCRAFT_LOOKUP}
          active={controls[MAP_CONTROLS.AIRCRAFT_LOOKUP]}
          onClick={(e) => {
            dispatch(toggleControl(MAP_CONTROLS.AIRCRAFT_LOOKUP));
          }}
        >
          <AircraftLookupModal isOpen={controls[MAP_CONTROLS.AIRCRAFT_LOOKUP]} />
        </MapControl>
        <MapControl
          title="Open map filters"
          className="map-controls__control"
          type={MAP_CONTROLS.FILTERS}
          active={controls[MAP_CONTROLS.FILTERS]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.FILTERS);
          }}
          dataBadge={filtersAppliedAmount ? String(filtersAppliedAmount) : null}
        />
        <MapControl
          title="Center map to your position"
          className="map-controls__control"
          type={MAP_CONTROLS.CENTER}
          onClick={centerMap}
        />
        <MapControl
          title="Select zoom preset"
          className="map-controls__control"
          type={MAP_CONTROLS.ZOOM_PRESET}
          active={controls[MAP_CONTROLS.ZOOM_PRESET]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.ZOOM_PRESET);
          }}
        />
        <MapControl.Combined className="map-controls__control">
          <MapControl type="zoomIn" title="Zoom map in" onClick={() => handleZoomChange(+1)} />
          <MapControl type="zoomOut" title="Zoom map out" onClick={() => handleZoomChange(-1)} />
        </MapControl.Combined>
        <MapControl
          className="map-controls__control"
          type="info"
          title="Info about virtower"
          dataInfo={`virtower ${new Date().getFullYear()}`}
        />
      </>
    );
  };

  const renderNoiseAbatementControls = () => {
    return (
      <>
        <MapControl
          title="Select layers"
          className="map-controls__control"
          type={MAP_CONTROLS.MAP_LAYERS}
          active={controls[MAP_CONTROLS.MAP_LAYERS]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.MAP_LAYERS);
          }}
        />
        <div className="map-controls__control-br" />
        <MapControl
          title="Select zoom preset"
          className="map-controls__control"
          type={MAP_CONTROLS.ZOOM_PRESET}
          active={controls[MAP_CONTROLS.ZOOM_PRESET]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.ZOOM_PRESET);
          }}
        />
        <MapControl.Combined className="map-controls__control">
          <MapControl type="zoomIn" title="Zoom map in" onClick={() => handleZoomChange(+1)} />
          <MapControl type="zoomOut" title="Zoom map out" onClick={() => handleZoomChange(-1)} />
        </MapControl.Combined>
        <MapControl
          className="map-controls__control"
          type="info"
          title="Info about virtower"
          dataInfo={`virtower ${new Date().getFullYear()}`}
        />
      </>
    );
  };

  const renderHallControls = () => {
    return (
      <>
        <MapControl
          className="map-controls__control map-controls__layers"
          type="layers"
          title="Select layers"
          active={controls[MAP_CONTROLS.MAP_LAYERS]}
          onClick={(e) => {
            handleOpenModal(e, MAP_CONTROLS.MAP_LAYERS);
          }}
        />
        <MapControl.Combined className="map-controls__control">
          <MapControl type="zoomIn" title="Zoom map in" onClick={() => handleZoomChange(+1)} />
          <MapControl type="zoomOut" title="Zoom map out" onClick={() => handleZoomChange(-1)} />
        </MapControl.Combined>
      </>
    );
  };

  return (
    <div className={clsx('map', { map_disclaimer: isDisclaimerVisible })} data-testid="map">
      {children({
        handleViewportChange,
      })}
      <div className={clsx('map-controls', { 'map-controls_hall': hall })}>
        {hall ? renderHallControls() : isNoiseAbatementPage ? renderNoiseAbatementControls() : renderAllControls()}
      </div>
      {isDisclaimerVisible && userSettings?.copy && <div className="map-disclaimer">{userSettings?.copy}</div>}
    </div>
  );
};

MapControls.propTypes = {
  children: PropTypes.func.isRequired,
  isBottomBarVisible: PropTypes.bool,
  isDisclaimerVisible: PropTypes.bool,
  hall: PropTypes.bool,
  isNoiseAbatementPage: PropTypes.bool,
};

export default MapControls;
