import React, { useState, useMemo, useEffect } from 'react';
import debounce from 'debounce';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { aircraftLookup, setAircraftLookupFetching, setAircraftLookupSelected, setViewport } from 'redux/modules/map';
import CustomSelect from 'components/base/CustomSelect';
import HighlightMatch from './HighlightMatch';

import './AircraftLookupModal.css';

const AircraftLookupModal = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { searchResults, fetching, searchTotal } = useSelector((state) => state.map.aircraftLookup);
  const [touchedSelection, setTouchedSelection] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  /** Search value is used for sending search requests, when searchInputValue, just represents input state */
  const [searchValue, setSearchValue] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const isSearchNotFound = searchValue && !fetching;

  const fetchAircraftCallback = useMemo(() => {
    return debounce((lookupQuery) => {
      dispatch(aircraftLookup(lookupQuery));
    }, 200);
  }, [dispatch]);

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('');
      setSearchInputValue('');
      setSearchOptions([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchValue) {
      fetchAircraftCallback(searchValue);
    } else {
      setSearchOptions([]);
      dispatch(setAircraftLookupFetching(false));
    }
  }, [searchValue, dispatch, fetchAircraftCallback]);

  useEffect(() => {
    if (searchResults?.length) {
      const options = searchResults.map((searchResult) => {
        const { item, matches = [] } = searchResult;
        const [closestMatch] = matches;
        const name = closestMatch?.value;

        return { value: item.hex, name, matches };
      });
      const isOptionsLessThenTotal = searchTotal !== searchResults.length;
      if (isOptionsLessThenTotal) {
        options.push({
          name: `${searchResults.length} of ${searchTotal}`,
          value: '--',
          disabled: true,
        });
      }
      setSearchOptions(options);
    } else {
      setSearchOptions([]);
    }
  }, [searchResults, searchTotal]);

  return (
    <div
      className={clsx('aircraft-lookup-form', {
        'aircraft-lookup-form_open': isOpen,
      })}
    >
      <CustomSelect
        placeholder="REG, FLIGHT, ICAO"
        name="aircraft-lookup"
        value={searchValue}
        options={searchValue ? searchOptions : []}
        loading={fetching}
        onChange={(newValue, newOption) => {
          const foundResult = searchResults.find((searchRes) => searchRes.item.hex === newValue);
          setSearchInputValue(newOption.name);
          if (foundResult) {
            const aircraft = { ...foundResult.item, off: false, isSelected: true, isTurnedOff: foundResult.item.off };
            const newViewport = { center: [aircraft.lat, aircraft.lon], zoom: aircraft.isTurnedOff ? 18 : 10 };
            dispatch(setAircraftLookupSelected(aircraft));
            dispatch(setViewport(newViewport));
          }
        }}
        renderValue={(valueProps) => {
          /** Dispatch event in order to update internal state, select first option and open select dropdown */
          const handleKeyDown = (e) => {
            if (e.key === 'Enter' && !fetching && !touchedSelection) {
              const event = new KeyboardEvent('keydown', {
                key: 'ArrowDown',
                code: 'ArrowDown',
                keyCode: 40,
                bubbles: true,
                cancelable: true,
              });
              e.target.dispatchEvent(event);
            }
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
              setTouchedSelection(true);
            }
            const focusEvent = new FocusEvent('focus', {
              bubbles: true,
              cancelable: true,
            });
            e.target.dispatchEvent(focusEvent);
            valueProps?.onKeyDown(e);
          };

          return (
            <input
              {...valueProps}
              className="custom-select__input"
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
                setSearchValue(e.target.value);
                setTouchedSelection(false);
                dispatch(setAircraftLookupFetching(true));
              }}
              onKeyDown={handleKeyDown}
            />
          );
        }}
        renderOption={(props, { matches, name }, snapshot, className) => {
          return matches ? (
            <button {...props} className={className} type="button">
              {matches.map((match, i) => {
                return (
                  <React.Fragment key={`${match.value}_${match.key}`}>
                    <HighlightMatch match={match} />
                    {i === matches.length - 1 ? '' : ', '}
                  </React.Fragment>
                );
              })}
            </button>
          ) : (
            <div className="custom-select__option-total">{name}</div>
          );
        }}
        emptyMessage={isSearchNotFound ? 'Not Found' : fetching ? 'Loading...' : undefined}
        condensed
        search
      />
    </div>
  );
};

AircraftLookupModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default AircraftLookupModal;
