/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState, useReducer, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import spacetime from 'spacetime';
import { GeoJSON, Marker } from "react-leaflet";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { SideModal } from 'components/common/SideNavigation';
import LeafletMap from 'components/common/LeafletMap';
import MapControls from 'components/common/MapControls';
import GeofenceOverlay from 'components/common/LeafletMap/components/GeofenceOverlay';
import FormField from 'components/base/FormField';
import FormGroup from 'components/base/FormGroup';
import CustomInput from 'components/base/CustomInput';
import FormikInput from 'components/base/CustomInput/FormikInput';
import CustomSelect from 'components/base/CustomSelect';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import LocationSearchInput from 'components/common/LocationSearch';
import { openNavbarModal, closeNavbarModal } from 'redux/modules/modal';
import { setCenter, setSources } from 'redux/modules/map';
import { useGeofenceLayer } from 'hooks/useGeofenceLayer';
import { permissions } from 'utils/userPermissions';
import api from 'api/api';
import {
  getDatesArray,
  filterNoiseData,
  makeSplitData,
  makeCorrectedData,
  ALTITUDE_TYPE_RAW,
  ALTITUDE_TYPE_GNSS_RAW,
  ALTITUDE_TYPE_GNSS_CORRECTED,
  ALTITUDE_TYPE_QNH_CORRECTED,
  ALTITUDE_TYPE_QFE_CORRECTED,
  ALTITUDE_TYPE_QNHS_CORRECTED,
} from '../NoiseAbatement/noiseAbatementHelpers';
import { FlightHistorySettings } from '../../components/common/FlightHistory'
import { exportNoiseToKmlString } from '../NoiseAbatement/noiseExport';
import { ReactComponent as Location } from '../NoiseAbatement/locationIcon.svg';

import '../NoiseAbatement/NoiseAbatement.css';
import DNDModal from '../../components/base/DNDModal';
import Page from 'components/common/Page';

const customIcon = new window.L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/684/684908.png', // Укажите путь к иконке
  iconSize: [40, 41], // Размер иконки
  iconAnchor: [20, 41], // Точка привязки
});

const MODAL_NAME = 'flightHistory';

const todayDateInputValue = new Date().toISOString().slice(0, 10);

const initialFiltersState = {
  alt: null,
  reg: null,
  fli: null,
  smp: false,
  activity: null,
  altitudeType: ALTITUDE_TYPE_RAW,
};

const altitudeTypeOptions = [
  { name: 'Raw', value: ALTITUDE_TYPE_RAW },
  { name: 'GNSS Raw', value: ALTITUDE_TYPE_GNSS_RAW },
  { name: 'GNSS Geoid Corrected', value: ALTITUDE_TYPE_GNSS_CORRECTED },
  { name: 'QNH Corrected', value: ALTITUDE_TYPE_QNH_CORRECTED },
  { name: 'QFE Corrected', value: ALTITUDE_TYPE_QFE_CORRECTED },
  { name: 'QNHS Corrected', value: ALTITUDE_TYPE_QNHS_CORRECTED },
];

const filtersReducer = (state, action) => {
  return { ...state, ...action };
};

const getNewGlobalRequestId = () => {
  return new Date().getTime() + Math.round(Math.random() * 10);
};

let globalRequestId = getNewGlobalRequestId();

const MAX_DISTANCE_TO_SPLIT = 4 / 111.32; // MAX ~4km of travel per those 2-3 seconds
function splitPathIntoTracks(data) {
  const tracks = [];

  for (let i = 0; i < data.fpth.length; i++) {
    const point = data.fpth[i];
    let addedToTrack = false;

    for (let j = 0; j < tracks.length; j++) {
      const currentTrack = tracks[j];
      const lastPoint = currentTrack[currentTrack.length - 1];
      const distance = calculateDistanceEuclidean(lastPoint.lon, lastPoint.lat, point.lon, point.lat);

      if (distance <= MAX_DISTANCE_TO_SPLIT) {
        currentTrack.push(point);
        addedToTrack = true;
        break; // No need to check other tracks
      }
    }

    if (!addedToTrack) {
      // If the point was not added to any existing track, create a new track
      tracks.push([point]);
    }
  }

  return tracks;
}

function calculateDistanceEuclidean(x1, y1, x2, y2) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.sqrt(dx * dx + dy * dy);
}

const NoiseAbatement = () => {
  const dispatch = useDispatch();
  const { currentSidebarModal } = useSelector((state) => state.modal);
  const { timezone, airport } = useSelector((state) => state.auth);
  const { permissionsList } = useSelector((state) => state.auth.userInfo);
  const { mapType, mapWeatherRadarType, attributes, zoomBounds, viewport, sources, origins } = useSelector(
    (state) => state.map
  );
  const { center } = viewport;
  const {
    measureUnits: { altitude },
  } = useSelector((state) => state.settings);
  const isActivityFilterAvailable = useMemo(
    () => permissionsList.includes(permissions.OPTION_AIRPORT_DEFAULT),
    [permissionsList]
  );

  const [isFetching, setIsFetching] = useState(false);
  const [location, setLocation] = useState();
  const [address, setAddress] = useState();
  const setLocationFromSearch = (latLng, locationName) => {
    setLocation(latLng);
    setAddress(locationName);
    if (latLng) dispatch(setCenter([latLng.lat, latLng.lng]));
  };
  const [geofences, geofenceLayer] = useGeofenceLayer();
  const [filters, dispatchFilter] = useReducer(filtersReducer, initialFiltersState);

  const [noiseData, setNoiseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [processedNoiseData, setProcessedNoiseData] = useState([]);

  const [flightsPerActivityHash, setFlightsPerActivityHash] = useState(null);
  const [operationsOptions, setOperationsOptions] = useState([]);

  useEffect(() => {
    dispatch(setSources({ m: false }));
  }, [dispatch]);

  useEffect(() => {
    const initialOptions = [{ name: '--', value: '-1' }];
    const hash = flightsPerActivityHash || {};
    const options = Object.keys(hash).map((eventName) => {
      return { name: eventName, value: eventName };
    });

    const sortedOptions = options.sort((optionA, optionB) =>
      optionA.name.trim().localeCompare(optionB.name.trim(), 'en', { numeric: true })
    );

    const allOptions = [...initialOptions, ...sortedOptions];

    setOperationsOptions(allOptions);
    if (!options.length) {
      dispatchFilter({ activity: null });
    }
  }, [flightsPerActivityHash]);

  const [dataChunksLoaded, setDataChunksLoaded] = useState(0);
  const [dataChunksMax, setDataChunksMax] = useState(0);
  const [dataRangeString, setDataRangeString] = useState('');

  const [chosenNoiseFlight, setChosenNoiseFlight] = useState(null);
  const setChosenNoiseFullFlight = useCallback(
    (flightpath) => {
      const fullFlightpath = filteredData.find((path) => {
        return path.utireg === flightpath?.utiregInitial;
      });
      setChosenNoiseFlight(fullFlightpath);
    },
    [setChosenNoiseFlight, filteredData]
  );
  const [flightTagData, setFlightTagData] = useState(null);

  useEffect(() => {
    dispatch(openNavbarModal(MODAL_NAME));
  }, [dispatch]);

  const clearData = () => {
    setNoiseData([]);
    setFlightsPerActivityHash(null);
  };

  const { airport: localAirport } = useSelector((state) => state.auth);

  const [centerPoint, setCenterPoint] = useState([localAirport.lat,localAirport.lon]);
  const [geojsonData, setGeojsonData] = useState(null);
  const [selectType, setSelectType] = useState('airport')
  const [layerKey, setLayerKey] = useState(0);

  const onMapClick = (e) => {

    if (selectType === 'airport') return;

    const points = Object.values(e.latlng).map(coordinates => +coordinates.toFixed(6))
    setCenterPoint(points)
  }

  const handleUpdateHistoryLayer = (updatedGeojson) => {
    if (!updatedGeojson?.features) {
      setGeojsonData(null);
    } else {
      setGeojsonData(updatedGeojson);
    }

    setLayerKey((prevKey) => prevKey + 1); // обновляем ключ
  };

  const onCenterTypeChange = (type) => {
    setSelectType(type)
    if (type === 'byMap') return

    setCenterPoint([localAirport.lat,localAirport.lon])
  }

  const style = {
    color: "white", // Цвет линии
    weight: 2.5,     // Толщина линии
    opacity: 1,  // Прозрачность линии
  };

  return (
    <Page fullscreen>
      <FlightHistorySettings centerPoint={centerPoint} onCenterTypeChange={onCenterTypeChange} onHistoryResponse={handleUpdateHistoryLayer} setLocationFromSearch={setLocationFromSearch} />
      <MapControls isNoiseAbatementPage>
        {({ handleViewportChange }) => (
          <LeafletMap
            className="map map__noise"
            onViewportChanged={handleViewportChange}
            mapType={mapType}
            mapWeatherRadarType={mapWeatherRadarType}
            zoomBounds={zoomBounds}
            viewport={viewport}
            aircraftsOverlay={false}
            attributes={attributes}
            onMapClick={onMapClick}
            renderGeofences={() => {
              return <GeofenceOverlay geofenceLayer={geofenceLayer} geofences={geofences}  />;
            }}
          >
            { geojsonData && <GeoJSON key={layerKey} data={geojsonData} style={style}  />}
            <Marker position={centerPoint} icon={customIcon} />
          </LeafletMap>
        )}
      </MapControls>
    </Page>
  );
};

export default NoiseAbatement;
