import React from 'react';
import "./Button.css"


const Button = ({onClick, children, text, size = '', type = '', className = '', rounded = ''}) => {
  return (<button onClick={onClick} className={`vt-button ${size} ${type} ${className} ${rounded ? 'rounded' : ''}`}>
    { children || text }
  </button> );
}
 
export { Button };