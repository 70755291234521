import React from 'react';
import PropTypes from 'prop-types';
import { Polygon } from 'react-leaflet';
import { GeofencePropType } from 'utils/commonPropTypes';
import { calculateCenter } from 'utils/mapUtils';
import CustomMarker from '../CustomMarker';

import './GeofenceOverlay.css';

export const getGeofenceColor = ({ isGround, active, isAirport }) => {
  if (!active) return '#7f8a93';
  if (isGround) return '#ea1515';
  if (isAirport) return '#acc717';
  return '#fdc900';
};

/**
 * Extracts coordinates from a geofence object and returns them as an array.
 *
 * @param {Object} geofence - The geofence object containing latitude and longitude properties.
 * @param {number} geofence.lat1 - Latitude of point 1.
 * @param {number} geofence.lon1 - Longitude of point 1.
 * @param {number} geofence.lat2 - Latitude of point 2.
 * @param {number} geofence.lon2 - Longitude of point 2.
 * @param {number} geofence.lat3 - Latitude of point 3.
 * @param {number} geofence.lon3 - Longitude of point 3.
 * @param {number} geofence.lat4 - Latitude of point 4.
 * @param {number} geofence.lon4 - Longitude of point 4.
 *
 * @returns {Array<Array<number>>} - An array of coordinates where each element is a pair [latitude, longitude].
 *
 * @example
 * const geofence = { lat1: 37.7749, lon1: -122.4194, lat2: 34.0522, lon2: -118.2437 };
 * const coordsArray = getCoordsArrayFromGeofence(geofence);
 * // Returns [[37.7749, -122.4194], [34.0522, -118.2437]], an array of coordinates extracted from the geofence object.
 */
export const getCoordsArrayFromGeofence = (geofence) => {
  const coordsArray = [];
  for (let i = 1; i <= 4; i += 1) {
    if (geofence[`lat${i}`] && geofence[`lon${i}`]) {
      coordsArray.push([parseFloat(geofence[`lat${i}`]), parseFloat(geofence[`lon${i}`])]);
    }
  }
  return coordsArray;
};

function GeofenceLayer({ geofenceLayer, geofences }) {
  const geofencesEnabled = geofenceLayer && geofences && geofences.length > 0;
  const renderGeofences = () => {
    return geofences.map((geofence) => {
      const coordsArray = getCoordsArrayFromGeofence(geofence);
      return (
        <React.Fragment key={geofence.id}>
          <Polygon
            color={getGeofenceColor(geofence)}
            weight={2}
            positions={coordsArray}
            opacity={geofence.isAirport ? 0.35 : 1}
            pmIgnore={true}
          />
          <CustomMarker position={calculateCenter(coordsArray)} className="marker_geofence" pmIgnore={true}>
            <div className="marker_geofence__text">
              <div>{geofence.id}</div>
              <div className="marker_geofence__content">{geofence.name}</div>
              <div className="marker_geofence__content">{`lower: ${geofence.lowerAlt} / upper: ${geofence.upperAlt}`}</div>
            </div>
          </CustomMarker>
        </React.Fragment>
      );
    });
  };
  return geofencesEnabled && renderGeofences();
}

GeofenceLayer.propTypes = {
  geofenceLayer: PropTypes.bool,
  geofences: PropTypes.arrayOf(GeofencePropType),
};

export default GeofenceLayer;
