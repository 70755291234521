import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchGeofences } from 'redux/modules/geofence';
import { handleToasts } from 'utils/handleThunkRequest';

export const useGeofenceLayer = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { viewport, attributes } = useSelector((state) => state.map);
  const { geofences } = useSelector((state) => state.geofence);
  const { geofenceLayer } = attributes;

  useEffect(() => {
    if (geofenceLayer) {
      dispatch(fetchGeofences({ airports: true, bounds: viewport.bounds })).then(handleToasts());
    }
  }, [userInfo, dispatch, geofenceLayer, viewport]);

  return [geofences, geofenceLayer];
};
