import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { MAP_CONTROLS } from 'utils/globalConstants';
import icons from './icons';

import './MapControl.css';

const MapControl = ({
  type = 'center',
  title,
  className,
  disabled,
  active,
  dataInfo,
  dataBadge,
  onClick,
  children,
  innerClassName,
}) => {
  return children ? (
    <div className={clsx('map-control__wrap', className)}>
      <div className={clsx('map-control__inner-wrap', innerClassName)}>
        {children}
        <button
          type="button"
          className={clsx('map-control', {
            'map-control_active': active,
            [`map-control_type_${type}`]: true,
            'map-control_badge': dataBadge,
          })}
          title={title}
          disabled={disabled}
          data-info={dataInfo}
          data-badge={dataBadge}
          onClick={onClick}
        >
          {icons[type]}
        </button>
      </div>
    </div>
  ) : (
    <button
      type="button"
      className={clsx('map-control', className, {
        'map-control_active': active,
        [`map-control_type_${type}`]: true,
        'map-control_badge': dataBadge,
      })}
      title={title}
      disabled={disabled}
      data-info={dataInfo}
      data-badge={dataBadge}
      onClick={onClick}
    >
      {icons[type]}
    </button>
  );
};

MapControl.propTypes = {
  type: PropTypes.oneOf([
    MAP_CONTROLS.CENTER,
    MAP_CONTROLS.ZOOM_PRESET,
    MAP_CONTROLS.ZOOM_IN,
    MAP_CONTROLS.ZOOM_OUT,
    MAP_CONTROLS.MAP_LAYERS,
    MAP_CONTROLS.FLIGHTS,
    MAP_CONTROLS.FILTERS,
    MAP_CONTROLS.LIVE_ATC,
    MAP_CONTROLS.INFO,
    MAP_CONTROLS.AIRCRAFT_LOOKUP,
  ]).isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  dataInfo: PropTypes.string,
  dataBadge: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const Combined = ({ children, className }) => {
  return (
    <div className={clsx('map-control_combined-wrap', className)}>
      {children.map((child) =>
        React.cloneElement(child, {
          key: child.props.type,
          className: clsx(child.props.className, 'map-control_combined'),
        })
      )}
    </div>
  );
};

Combined.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
};

MapControl.Combined = Combined;

export default MapControl;
