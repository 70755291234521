import CustomSelect from 'components/base/CustomSelect';
import { Slider } from 'components/base/Slider';
import { Button } from 'components/UI/Button';
import React, { useEffect, useState } from 'react';

const activities = [
  {
    name: 'Landing XYZ',
    value: '1'
  },
]

const defaultValue = { altitude: 5000 }

const FlightHistoryFilter = ({ onChange, onSubmit }) => {
  const [filterData, setFilterData] = useState(defaultValue)

  const handleFilterDataChange = (data) => setFilterData({...filterData, ...data}) 

  const onAltitudeChange = (alt) => {
    handleFilterDataChange({ altitude: alt })
  }

  const resetData = () => setFilterData(defaultValue)

  useEffect(() => onChange(filterData), [filterData])

  return (    
  <div className='flight-history-block'>
    <div className='flight-history-block__header'>
      <div className='flight-history-block__title'>Filter</div>
      <Button text='Reset filters' type='outline' size='sm' rounded onClick={resetData} />
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__label'>
        Select Altitude/Ceiling
      </div>
      <Slider onChange={onAltitudeChange} value={filterData.altitude} max={100000} inputLabel={'ft/m'} showInput />
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__filter'>
        <div className='flight-history-data__filter-block'>
          <div className='flight-history-data__label'>
            Registration
          </div>
          <input type="text" placeholder='Reg №' />
        </div>
        <div className='flight-history-data__filter-block'>
          <div className='flight-history-data__label'>
            Flight/Callsign
          </div>
          <input type="text" placeholder='Aircraft flight/callsign' />
        </div>
      </div>
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__label'>
        Activity
      </div>
      <CustomSelect options={activities} condensed />
      <div className='flight-history-data__filter-submit'>
        <Button className='flight-history-w-full' text='Load Data' onClick={onSubmit} type='primary' />
      </div>
    </div>
  </div>)
}

export { FlightHistoryFilter }