import AccordionTab from 'components/base/AccordionTab';
import CustomSelect from 'components/base/CustomSelect';
import { Button } from 'components/UI/Button';
import React, { useState } from 'react';

const exportTypes = [
  {
    name: 'KML',
    value: 'kml'
  },
  {
    name: 'KMZ',
    value: 'kmz'
  }
]

const altitudeTypes = [
  {
    name: 'Pull down',
    value: '1'
  },
  {
    name: 'Pull up',
    value: '2'
  }
]

const FlightHistoryExport = () => {

  const [isExportOpened, setExportOpened] = useState(false)

  const onExportToggle = (value) => setExportOpened(value)

  return (<div className='flight-history-block flight-history-block--export'>
    <AccordionTab label='Export' name='test' opened={isExportOpened} setOpened={onExportToggle}>
      <div className='flight-history-data__label'>
        Altitude Type
      </div>
      <CustomSelect options={altitudeTypes} className="flight-history-mb-5" />

      <div className='flight-history-data__label'>
        File Type
      </div>
      <CustomSelect className='flight-history-mb-15' options={exportTypes} />
      <Button className='flight-history-w-full' text='Export' type='primary' />
    </AccordionTab>
  </div>)
}

export { FlightHistoryExport }