import React, { useEffect, useRef } from 'react';
import './Slider.css'; 

const Slider = ({ onChange, value, inputLabel, showInput = false, min = 0, max = 100 }) => {
  const sliderRef = useRef(null); 
  const progressScript = () => {
    const sliderValue = sliderRef.current.value;
  
    const percentage = (sliderValue / max) * 100;
  
    sliderRef.current.style.background = `linear-gradient(to right, var(--blue-400) ${percentage}%, var(--color-fg-nav-menu) ${percentage}%)`;
  }

  const handleChange = (e) => {
    const sliderValue = +e.target.value

    progressScript()
    onChange(sliderValue)
  };

  useEffect(() => {
    progressScript()
  })

  return (
    <div className="range">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        id="range"
        ref={sliderRef}
        onChange={handleChange}
      />
      {showInput &&  <div className='range__block'>
        <input min={min} max={max} className='range__input' onChange={handleChange} value={value} type="number" />
        {inputLabel && <label>{inputLabel}</label>}
      </div> }
    </div>
  );
};

export { Slider };
