import { Slider } from 'components/base/Slider';
import { Button } from 'components/UI/Button';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'rsuite';

const generateCurrentDate = () => {
  const currentDate = new Date();
  const pastHour = new Date(currentDate.getTime() - 60 * 60 * 1000); 

  return {
    from: pastHour.toISOString(), 
    to: currentDate.toISOString() 
  };
}

const defaultValue = {
  centerType: 'airport',
  radius: 1000,
  ...generateCurrentDate()
}

const FlightHistoryData = ({ onChange, onCenterTypeChange }) => {
  const dateRangePickerRef = useRef();

  const [historyData, setHistoryData] = useState(defaultValue)

  const handleHistoryDataChange = (data) => setHistoryData({...historyData, ...data}) 

  const changeCenterType = (type) => {
    handleHistoryDataChange({
      centerType: type
    })

    onCenterTypeChange(type)
  }

  const handleDateSelect = (dateRange) => {
    if (!dateRange || !dateRange?.length) {
      handleHistoryDataChange(generateCurrentDate())
      return
    }

    const [from, to] = dateRange;

    handleHistoryDataChange({
      from: from.toISOString(),
      to: to.toISOString()
    })
  }

  const changeCenterPointRadius = (radius) => {
    handleHistoryDataChange({ radius })
  } 

  const resetData = () => {
    const datePickerClearButton = dateRangePickerRef.current.root.querySelector('.rs-picker-clean')

    if (datePickerClearButton) datePickerClearButton.click()

    setHistoryData(defaultValue)
  }

  useEffect(() => onChange(historyData), [historyData])


  return (<div className='flight-history-block'>
    <div className='flight-history-block__header'>
      <div className='flight-history-block__title'>Time, Center, Range</div>
      <Button text='Reset filters' type='outline' size='sm' rounded onClick={resetData} />
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__label'>
        Time and Date
      </div>
      <div>
        <DateRangePicker ref={dateRangePickerRef} size='sm' className='flight-history-datepicker' placeholder="Select flight date range" format="MM/dd/yyyy hh:mm aa" onChange={(range) => handleDateSelect(range)} />
      </div>
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__label'>
        Select Center Point
      </div>
      <div className='flight-history-data__center-point'>
        <Button onClick={() => changeCenterType('airport')} className='flight-history-mr-5' text='Airport' type={historyData.centerType === 'airport' ? 'primary' : ''} size='sm' />
        <Button onClick={() => changeCenterType('byMap')} text='Set on Map' size='sm' type={historyData.centerType === 'byMap' ? 'primary' : ''} />
      </div>
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__label'>
        Center radius
      </div>
      <Slider max={300000} value={historyData.radius} inputLabel={'NM'} showInput onChange={(radius) => changeCenterPointRadius(radius)} />
    </div>
  </div>)
}

export { FlightHistoryData }