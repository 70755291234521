import React from 'react';
import { MAP_CONTROLS } from 'utils/globalConstants';

const center = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 21.5 21.5">
    <path
      className="map-control__icon-path"
      d="M10.75 0A10.75 10.75 0 1021.5 10.75 10.76 10.76 0 0010.75 0m.75 20v-2.76a.75.75 0 00-1.5 0V20a9.25 9.25 0 01-8.46-8.5h2.72a.75.75 0 000-1.5H1.54A9.25 9.25 0 0110 1.54v2.72a.75.75 0 001.5 0V1.54A9.25 9.25 0 0120 10h-2.76a.75.75 0 000 1.5H20a9.25 9.25 0 01-8.5 8.5"
    />
  </svg>
);

const zoomPreset = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="29" viewBox="0 0 29.5 32.96">
    <path
      className="map-control__icon-path"
      d="M21.75 31.26L15.5 25a8.85 8.85 0 10-1.45 1.38l6.28 6.28A1 1 0 0021 33a1 1 0 00.71-.29 1 1 0 000-1.41M1.6 19.42a7.17 7.17 0 117.16 7.16 7.17 7.17 0 01-7.16-7.16M23.92.18l1.63 3.31a.34.34 0 00.24.18l3.66.53a.32.32 0 01.18.54L27 7.32a.32.32 0 00-.09.28l.62 3.64a.32.32 0 01-.46.34l-3.29-1.72a.3.3 0 00-.29 0l-3.28 1.72a.32.32 0 01-.46-.34l.63-3.64a.32.32 0 00-.09-.28l-2.65-2.58a.32.32 0 01.17-.54l3.66-.53a.3.3 0 00.24-.18L23.35.18a.32.32 0 01.57 0"
    />
  </svg>
);

const info = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="6" viewBox="0 0 6.19 13.04">
    <path
      className="map-control__icon-path"
      d="M4.84 12.23V5.31h-.01v-.49H.06v.98H1.4v6.43H0v.81h6.19v-.81H4.84zM5 1.91A1.91 1.91 0 113.12 0 1.92 1.92 0 015 1.91"
    />
  </svg>
);

const zoomIn = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19.24 19.24">
    <path className="map-control__icon-path" d="M19.24 7.62h-7.62V0h-4v7.62H0v4h7.62v7.62h4v-7.62h7.62v-4z" />
  </svg>
);

const zoomOut = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 18.77 3.91">
    <path d="M0 0h18.77v3.91H0z" className="map-control__icon-path" />
  </svg>
);

const layers = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30.2 20.81">
    <path
      className="map-control__icon-path"
      d="M29.9,16.31l-4-3,2.82-.48a.75.75,0,0,0,.6-.56.74.74,0,0,0-.27-.77l-4-3L27.93,8a.75.75,0,0,0,.6-.56.77.77,0,0,0-.27-.78L19.79.16A.76.76,0,0,0,19.21,0L.62,3.17A.75.75,0,0,0,.29,4.5l4,3L1.45,8a.75.75,0,0,0-.61.56.76.76,0,0,0,.28.78l4,3-2.82.48a.75.75,0,0,0-.33,1.34l8.47,6.45a.75.75,0,0,0,.45.16l.13,0,18.58-3.15a.76.76,0,0,0,.61-.56.74.74,0,0,0-.28-.77M19.14,1.55,26,6.76l-2.8.47h0L9.41,9.57,6.56,7.39h0l-4-3ZM5.9,8.78,8.76,11a.75.75,0,0,0,.46.15h.12L23.08,8.77,26.8,11.6l-2.8.48h0L10.23,14.41,7.38,12.24h0l-4-3Zm5.16,10.48L4.22,14.05l2.51-.42L9.58,15.8A.76.76,0,0,0,10,16h.12L23.9,13.62l3.72,2.83Z"
    />
  </svg>
);

const flights = (
  <svg className="map-control__icon" xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 20.81">
    <path
      className="map-control__icon-path"
      d="M4.66,16.71,0,15.7l.92-1.85,4.3-.23a3.44,3.44,0,0,1,.46-.6c.21-.25.4-.45.55-.6l3.88-3.93L2,4.52l2.07-2,10.3,1.8L18,.65a1,1,0,0,1,.35-.3,3,3,0,0,1,.44-.21A2.71,2.71,0,0,1,19.34,0l.6,0a4.87,4.87,0,0,1,.72.05l.34,0,.19.05.09.23a3.11,3.11,0,0,1,.16.85,3.07,3.07,0,0,1-.07.86,2.38,2.38,0,0,1-.21.76,1.85,1.85,0,0,1-.39.58L17.13,7.11l1.8,10.25-2.08,2-4-8.08L9.28,15c-.22.18-.49.43-.81.74s-.56.52-.71.64l-.33,4-1.8,1Z"
    />
  </svg>
);

const atc = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20.01 22.31">
    <g>
      <path
        className="map-control__icon-path"
        d="M5,15.26A1.38,1.38,0,0,0,3.5,14.17,1.38,1.38,0,0,0,2,15.26L.25,22a.43.43,0,0,1,0,.05.18.18,0,0,0,.19.18H1.57a.21.21,0,0,0,.2-.18l.5-2H4.74l.49,2a.22.22,0,0,0,.2.18H6.57A.18.18,0,0,0,6.76,22a.43.43,0,0,1,0-.05ZM2.56,18.78l.77-3.18c0-.13.06-.18.17-.18s.14.05.17.18l.78,3.18Z"
      />
      <path
        className="map-control__icon-path"
        d="M11.89,14.25H6.65a.21.21,0,0,0-.22.21v.85a.2.2,0,0,0,.22.19H8.52V22a.21.21,0,0,0,.22.21H9.8A.21.21,0,0,0,10,22V15.5h1.87a.2.2,0,0,0,.22-.19v-.85A.21.21,0,0,0,11.89,14.25Z"
      />
      <path
        className="map-control__icon-path"
        d="M17.37,14.34a8.49,8.49,0,0,0-1.7-.17c-1.29,0-3,.59-3,3.28V19c0,2.65,1.74,3.28,3,3.28a8.49,8.49,0,0,0,1.7-.17c.13,0,.23-.1.23-.25v-.72c0-.13-.05-.22-.21-.22h0c-.38,0-1.11.08-1.68.08-.9,0-1.55-.51-1.55-2V17.45c0-1.52.65-2,1.55-2,.57,0,1.3.05,1.68.08h0a.19.19,0,0,0,.21-.22v-.72C17.6,14.44,17.5,14.38,17.37,14.34Z"
      />
      <path
        className="map-control__icon-path"
        d="M2.48,8.14A11,11,0,0,0,4.3,8c.16,0,.22-.07.22-.23V7.08a.22.22,0,0,0-.22-.22h-2c-.62,0-.84-.21-.84-.92V.3A.21.21,0,0,0,1.28.09H.21A.21.21,0,0,0,0,.3V5.94C0,7.76,1.08,8.14,2.48,8.14Z"
      />
      <path
        className="map-control__icon-path"
        d="M5.75,8.06H6.81A.22.22,0,0,0,7,7.84V.29A.21.21,0,0,0,6.81.08H5.75a.21.21,0,0,0-.21.21V7.84A.21.21,0,0,0,5.75,8.06Z"
      />
      <path
        className="map-control__icon-path"
        d="M14.6.32s0,0,0,0a.18.18,0,0,0-.19-.18H13.28a.22.22,0,0,0-.2.18L11.52,6.71c0,.13-.06.18-.17.18s-.14,0-.17-.18L9.63.27A.23.23,0,0,0,9.42.09H8.29a.18.18,0,0,0-.2.18s0,0,0,0L9.86,7.05a1.38,1.38,0,0,0,1.49,1.09,1.38,1.38,0,0,0,1.49-1.09Z"
      />
      <path
        className="map-control__icon-path"
        d="M17.61,1.28H19.8A.21.21,0,0,0,20,1.06V.38C20,.22,20,.17,19.8.15a10.49,10.49,0,0,0-2-.15c-1.4,0-2.48.38-2.48,2.2V5.94c0,1.82,1.08,2.2,2.48,2.2a10.49,10.49,0,0,0,2-.15C20,8,20,7.92,20,7.76V7.08a.21.21,0,0,0-.21-.22H17.61c-.62,0-.85-.21-.85-.92V4.56h2.91a.21.21,0,0,0,.21-.22V3.57a.21.21,0,0,0-.21-.21H16.76V2.2C16.76,1.49,17,1.28,17.61,1.28Z"
      />
    </g>
  </svg>
);

const metaf = (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" viewBox="0 0 33.6 22.8">
    <path
      className="map-control__icon-path"
      d="M6.9,8.3H5.8C5.7,8.2,5.6,8.1,5.6,8V2.9c0-0.1,0-0.1,0-0.1L5.5,2.9L4.5,5C4.4,5.1,4.3,5.2,4.1,5.2H3C2.8,5.2,2.7,5.1,2.6,5L1.6,2.9L1.5,2.8c0,0,0,0,0,0.1V8c0,0.1-0.1,0.2-0.2,0.2H0.2C0.1,8.3,0,8.2,0,8.1V0.5c0-0.2,0.2-0.4,0.4-0.4h0.9c0.2,0,0.3,0.1,0.4,0.3l1.7,3.4c0.1,0.1,0.1,0.1,0.1,0.1s0.1,0,0.1-0.1l1.7-3.4c0.1-0.2,0.2-0.3,0.4-0.3h0.9c0.2,0,0.4,0.2,0.4,0.4v7.6C7.1,8.2,7,8.3,6.9,8.3"
    />
    <path
      className="map-control__icon-path"
      d="M13.4,8.2c-0.7,0.1-1.4,0.2-2.1,0.1C9.8,8.3,8.7,8,8.7,6.1V2.2C8.7,0.4,9.8,0,11.3,0c0.7,0,1.4,0,2.1,0.1c0.2,0,0.2,0.1,0.2,0.2v0.7c0,0.1-0.1,0.2-0.2,0.2l0,0h-2.2c-0.6,0-0.9,0.2-0.9,0.9v1.2h3c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0v0.8c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0h-3v1.5c0,0.7,0.2,1,0.9,1h2.2c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0v0.7c0,0.2-0.1,0.2-0.2,0.2"
    />
    <path
      className="map-control__icon-path"
      d="M20.1,1.4h-1.9V8c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0h-1.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0V1.4h-1.9c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0V0.3c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0h5.4c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0v0.9C20.3,1.3,20.2,1.4,20.1,1.4C20.1,1.4,20.1,1.4,20.1,1.4"
    />
    <path
      className="map-control__icon-path"
      d="M26.5,8.3h-1.2c-0.1,0-0.2-0.1-0.2-0.2L24.6,6h-2.5l-0.5,2.1c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1l1.8-6.9c0.1-0.7,0.8-1.2,1.5-1.1c0.7-0.1,1.4,0.4,1.5,1.1L26.6,8c0,0,0,0,0,0.1C26.6,8.2,26.6,8.3,26.5,8.3C26.5,8.3,26.5,8.3,26.5,8.3 M23.4,1.5c0-0.1-0.1-0.2-0.2-0.2s-0.1,0-0.2,0.2l-0.8,3.3h1.9L23.4,1.5z"
    />
    <path
      className="map-control__icon-path"
      d="M33.5,8.3h-1.3C32.1,8.3,32,8.1,32,8l-1.6-2.9h-0.1c-0.3,0-0.7,0-1,0v3c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0h-1.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0V0.5c0-0.2,0.1-0.3,0.4-0.3c0.7-0.1,1.4-0.1,2.2-0.1c1.7,0,3.2,0.6,3.2,2.5v0.1c0.1,1-0.5,1.9-1.5,2.2l1.7,3.2C33.6,8.1,33.6,8.2,33.5,8.3C33.6,8.3,33.6,8.3,33.5,8.3C33.5,8.3,33.5,8.3,33.5,8.3 M31.8,2.5c0-0.8-0.5-1.2-1.6-1.2c-0.2,0-0.8,0-1,0v2.5h1C31.3,3.8,31.8,3.5,31.8,2.5L31.8,2.5z"
    />
    <path
      className="map-control__icon-path"
      d="M13,15.9h-1.9v6.7c0,0.1-0.1,0.2-0.2,0.2l0,0H9.8c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0v-6.7H7.6c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0v-0.9c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0H13c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0v0.9C13.2,15.8,13.1,15.9,13,15.9C13,15.9,13,15.9,13,15.9"
    />
    <path
      className="map-control__icon-path"
      d="M19.4,22.8h-1.2c-0.1,0-0.2-0.1-0.2-0.2l-0.5-2.1H15l-0.5,2.1c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1l1.8-6.9c0.1-0.7,0.8-1.2,1.5-1.1c0.7-0.1,1.4,0.4,1.5,1.1l1.8,6.9c0,0,0,0,0,0.1C19.6,22.7,19.5,22.8,19.4,22.8C19.4,22.8,19.4,22.8,19.4,22.8 M16.4,16c0-0.1-0.1-0.2-0.2-0.2s-0.1,0-0.2,0.2l-0.8,3.3h1.9L16.4,16z"
    />
    <path
      className="map-control__icon-path"
      d="M25.2,15.8H23c-0.6,0-0.9,0.2-0.9,0.9v1.2h3c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0v0.8c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0h-3v3.4c0,0.1-0.1,0.2-0.2,0.2l0,0h-1.1c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0v-5.8c0-1.9,1.1-2.2,2.5-2.2c0.7,0,1.4,0,2.1,0.1c0.2,0,0.2,0.1,0.2,0.2v0.7C25.4,15.7,25.4,15.8,25.2,15.8L25.2,15.8"
    />
  </svg>
);

const filters = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.27 15.64"
    className="map-control__icon map-control__icon-filters"
  >
    <g id="Layer_2">
      <g id="Map_Navigation">
        <path className="map-control__icon-path" d="M23.27,2H1A1,1,0,0,1,1,0H23.27a1,1,0,0,1,0,2Z" />
        <path className="map-control__icon-path" d="M19.12,6.55h-14a1,1,0,0,1,0-2h14a1,1,0,0,1,0,2Z" />
        <path className="map-control__icon-path" d="M14.16,11.09H10.11a1,1,0,0,1,0-2h4.05a1,1,0,0,1,0,2Z" />
        <path
          className="map-control__icon-path"
          d="M12.14,15.64l-.2,0a.7.7,0,0,1-.19-.06.56.56,0,0,1-.17-.09l-.15-.12a1,1,0,0,1-.29-.71,1,1,0,0,1,.29-.71,1.58,1.58,0,0,1,.15-.12,1,1,0,0,1,.17-.1l.19-.05a1,1,0,0,1,.58.05,1.21,1.21,0,0,1,.32.22,1,1,0,0,1,.3.71,1,1,0,0,1-.3.71,1,1,0,0,1-.7.29Z"
        />
      </g>
    </g>
  </svg>
);

const aircraftLookup = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    viewBox="0 0 32 34"
    className="map-control__icon map-control__icon-aircraftLookup"
  >
    <g id="c">
      <path
        className="map-control__icon-path"
        d="M28.31,12.21l-.74,3.39-1.34-.67-.17-3.12c-.11-.07-.26-.18-.44-.34-.18-.16-.32-.29-.44-.4l-2.86-2.82-2.89,5.91-1.48-1.51,1.31-7.49-2.65-2.65c-.09-.07-.16-.15-.22-.25-.06-.1-.11-.21-.15-.32-.04-.13-.07-.27-.08-.4-.01-.13-.02-.28-.02-.44,0-.2.01-.37.03-.52.02-.15.03-.23.03-.25l.03-.13.17-.07c.2-.07.41-.11.62-.12.21-.01.42,0,.62.05.2.02.39.07.55.15.17.08.31.17.42.29l2.69,2.65,7.46-1.31,1.48,1.51-5.88,2.89,2.65,2.62c.13.16.31.35.54.59.22.24.38.41.47.52l2.92.24.71,1.31-3.36.71Z"
      />
      <path
        className="map-control__icon-path"
        d="M21.75,31.71l-6.24-6.24c1.26-1.52,2.02-3.47,2.02-5.59,0-4.83-3.93-8.76-8.76-8.76S0,15.03,0,19.87s3.93,8.76,8.76,8.76c1.99,0,3.82-.67,5.29-1.79l6.28,6.28c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02,0-1.41ZM1.6,19.87c0-3.95,3.21-7.16,7.16-7.16s7.16,3.21,7.16,7.16-3.21,7.16-7.16,7.16-7.16-3.21-7.16-7.16Z"
      />
    </g>
  </svg>
);

const icons = {
  [MAP_CONTROLS.CENTER]: center,
  [MAP_CONTROLS.ZOOM_PRESET]: zoomPreset,
  [MAP_CONTROLS.ZOOM_IN]: zoomIn,
  [MAP_CONTROLS.ZOOM_OUT]: zoomOut,
  [MAP_CONTROLS.INFO]: info,
  [MAP_CONTROLS.MAP_LAYERS]: layers,
  [MAP_CONTROLS.FLIGHTS]: flights,
  [MAP_CONTROLS.LIVE_ATC]: atc,
  [MAP_CONTROLS.FILTERS]: filters,
  [MAP_CONTROLS.METAF]: metaf,
  [MAP_CONTROLS.AIRCRAFT_LOOKUP]: aircraftLookup,
};

export default icons;
