import React from 'react';
import LocationSearchInput from '../LocationSearch';


const FlightHistoryAddress = ({setLocationFromSearch}) => {
  return ( <div className='flight-history-block'>
    <div className='flight-history-block__header'>
      <div className='flight-history-block__title'>Set Marker</div>
    </div>
    <div className='flight-history-data'>
      <div className='flight-history-data__label'>
        Find addresses
      </div>
      <LocationSearchInput className='history-location-search' onSelect={setLocationFromSearch} />
      {/* <CustomSelect condensed options={addresses} /> */}
    </div>
  </div>)
}

export { FlightHistoryAddress }